import './App.scss';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

import ArchivePage from './pages/Archive';
import Issue1 from './pages/Isuess/Issue1';
import Issue2 from './pages/Isuess/Issue2';
import Issue3 from './pages/Isuess/Issue3';
import Issue4 from './pages/Isuess/Issue4';
import Issue5 from './pages/Isuess/Issue5';
import Issue6 from './pages/Isuess/Issue6';
import Issue7 from './pages/Isuess/Issue7';
import Issue8 from './pages/Isuess/Issue8';
import Issue9 from './pages/Isuess/Issue9';
import Issue10 from './pages/Isuess/Issue10';
import Issue11 from './pages/Isuess/Issue11';
import Issue12 from './pages/Isuess/Issue12';
import Issue13 from './pages/Isuess/Issue13';
import Issue14 from './pages/Isuess/Issue14';
import Issue15 from './pages/Isuess/Issue15';

function App() {
  
  const location = useLocation();
  
  ReactGA.initialize('G-G35XTP1WBG');
  ReactGA.send({ 
    hitType: "pageview", 
    page: "/", 
    title: "Newsletter #14" 
  });

  return (
    <div className="App">

      <Routes>
        <Route path='/' element={<Issue15 />}  />
        <Route path='/archiwum/' element={<ArchivePage />}  />
        <Route path='/numer1/' element={<Issue1 />}  />
        <Route path='/numer2/' element={<Issue2 />}  />
        <Route path='/numer3/' element={<Issue3 />}  />
        <Route path='/numer4/' element={<Issue4 />}  />
        <Route path='/numer5/' element={<Issue5 />}  />
        <Route path='/numer6/' element={<Issue6 />}  />
        <Route path='/numer7/' element={<Issue7 />}  />
        <Route path='/numer8/' element={<Issue8 />}  />
        <Route path='/numer9/' element={<Issue9 />}  />
        <Route path='/numer10/' element={<Issue10 />}  />
        <Route path='/numer11/' element={<Issue11 />}  />
        <Route path='/numer12/' element={<Issue12 />}  />
        <Route path='/numer13/' element={<Issue13 />}  />
        <Route path='/numer14/' element={<Issue14 />}  />
        <Route path='/numer15/' element={<Issue15 />}  />

      </Routes>

    </div>
  );
}

export default App;
